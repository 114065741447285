@import "../../../assets/styles/app";

.textField {
  position: relative;
  height: 70px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  transition: 0.3s;
  padding-left: 20px;
  display: flex;
  align-items: center;

  &:hover {
    @include desktop {
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  .label {
    position: absolute;
    left: 20px;
    top: 26px;
    font-size: 14px;
    line-height: 125%;
    color: rgba(255, 255, 255, 0.5);
    user-select: none;
    transition: 0.3s;

    &_focus {
      top: 15px;
    }
  }

  .input {
    position: relative;
    background: none;
    border: none;
    outline: none;

    &_focus {
      top: 11px;
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
  }


  &_focus {
    border: 1px solid rgba(255, 255, 255, 0.0);
    background: rgba(255, 255, 255, 0.1);

    &:hover {
      border: 1px solid rgba(255, 255, 255, 0.0);
    }

  }



}