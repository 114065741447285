@import "../../../assets/styles/app";

.transactions {

  .cardTitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 125%;
    text-align: center;
    color: #fff;
  }

  .list {
    margin-top: 20px;
    max-width: 468px;
    margin-left: auto;
    margin-right: auto;

    @include desktop {
      width: 468px;
    }

    .item {
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 16px;
      height: 60px;
      padding-left: 20px;
      padding-right: 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not(:first-child) {
        margin-top: 8px;
      }

      .icon {
        @include centerColumn;
        border-radius: 8px;
        width: 40px;
        height: 40px;
        background: rgba(217, 217, 217, 0.05);

        svg {
          path {
            stroke: #FFF;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;

        p:nth-child(1) {
          font-size: 14px;
          line-height: 125%;
          text-align: center;
          color: rgba(255, 255, 255, 0.5);
        }

        p:nth-child(2) {
          margin-left: 8px;
          font-weight: 500;
          font-size: 14px;
          line-height: 125%;
          text-align: center;
          color: #fff;
        }

        .status {
          margin-left: 16px;
          @include centerRow;
          border-radius: 8px;
          width: 84px;
          height: 32px;
          background: rgba(231, 96, 54, 0.1);
          font-weight: 500;
          font-size: 12px;
          line-height: 125%;
          text-align: center;
          color: #e76036;

          &_completed {
            background: rgba(54, 165, 52, 0.1);
            color: #36a534;
          }
        }

      }

    }
  }

}