@import "../../assets/styles/app";

.presaleStages {
  @include container;
  position: relative;
  z-index: 3;
  padding-top: 48px;
  padding-bottom: 24px;

  @include desktop {
    padding-top: 58px;
    padding-bottom: 70px;
  }

  .inner {
    @include inner;

    h2.title {
      font-family: var(--font-family);
      font-weight: 500;
      font-size: 24px;
      line-height: 125%;
      text-align: center;
      color: #fff;

      @include desktop {
        font-size: 40px;
      }
    }

    .topCards {
      margin-top: 32px;

      @include desktop {
        margin-top: 28px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 32px;
        width: 1156px;
        margin-left: auto;
        margin-right: auto;
      }

      .topCard {
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 20px;
        background: #010302;
        padding: 20px;
        height: 471px;

        @include desktop {
          height: auto;
        }

        &:not(:first-child) {
          margin-top: 20px;

          @include desktop {
            margin-top: 0;
          }
        }

        .cardTitle {
          font-weight: 500;
          font-size: 20px;
          line-height: 125%;
          color: #fff;
        }

        .img {
          margin-top: 20px;
          display: block;
          width: 324px;
          height: 200px;
          margin-left: auto;
          margin-right: auto;
        }

        .items {
          margin-top: 20px;

          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:not(:last-child) {
              padding-bottom: 12px;
              border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            }

            &:not(:first-child) {
              padding-top: 12px;
            }

            p {
              font-size: 18px;
              line-height: 125%;

              &:nth-child(1) {
                font-weight: 400;
                color: rgba(255, 255, 255, 0.4);
              }

              &:nth-child(2) {
                font-weight: 500;
                color: #fff;
              }
            }
          }
        }
      }

    }

    .bottomCards {
      margin-top: 48px;

      @include desktop {
        margin-top: 32px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 32px;
        width: 1156px;
        margin-left: auto;
        margin-right: auto;
      }

      .bottomCard {
        position: relative;
        background: #010302;

        @include desktop {
          border: 1px solid rgba(255, 255, 255, 0.1);
          border-radius: 20px;
          padding: 24px;
          overflow: hidden;
        }

        &:not(:first-child) {
          margin-top: 48px;

          @include desktop {
            margin-top: 0;
          }

          &::before {
            position: absolute;
            content: "";
            display: block;
            left: 0;
            right: 0;
            top: -24px;
            height: 1px;
            background: rgba(255, 255, 255, 0.1);

            @include desktop {
              display: none;
            }
          }
        }

        p:nth-child(1) {
          font-weight: 500;
          font-size: 20px;
          line-height: 125%;
          text-align: center;
          color: #fff;

          @include desktop {
            text-align: left;
          }
        }

        p:nth-child(2) {
          margin-top: 12px;
          @include Hind;
          font-size: 18px;
          line-height: 140%;
          text-align: center;
          color: rgba(255, 255, 255, 0.4);

          @include desktop {
            text-align: left;
          }
        }

        img {
          @include onlyDesktop();
          position: absolute;
        }

        &:nth-child(1) {

          p:nth-child(2) {
            @include desktop {
              width: 360px;
            }
          }

          img {
            width: 214px;
            height: 222px;
            top: -15px;
            right: -27px;
          }
        }

        &:nth-child(2) {

          p:nth-child(2) {
            @include desktop {
              width: 286px;
            }
          }

          img {
            width: 369px;
            height: 305px;
            top: -60px;
            right: -93px;
          }
        }
      }
    }


  }
}