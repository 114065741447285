@import "../../../../assets/styles/app";

.valueLabelComponent {
  @include centerColumn;
  width: 127px;
  height: 73px;

  p:nth-child(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    text-align: center;
    color: #fff;
  }

  p:nth-child(2) {
    margin-top: 2px;
    @include Hind;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.02em;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
  }


}