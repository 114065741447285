@import "../../assets/styles/app";

.header {
  position: absolute;
  z-index: 110;
  left: 0;
  top: 0;
  right: 0;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include desktop {
    padding: 24px 48px;
    justify-content: flex-end;
  }

  .logo {
    width: 99px;
    height: 24px;

    svg {
      width: 99px;
      height: 24px;
    }

    @include desktop {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .right {
    display: flex;
    align-items: flex-end;

    .signInBtn {
      @include onlyDesktop(flex);
      width: 98px;

      @include desktop {
        order: 2;
        margin-left: 8px;
      }
    }

    .joinPresaleBtn {
      //@include onlyDesktop(flex);
      width: 132px;
      margin-left: 8px;

      @include desktop {
        order: 3;
      }
    }

    .burgerBtn {
      @include button;
      //@include onlyMobile(flex);
      @include centerRow;
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 999px;
      width: 40px;
      height: 40px;
      margin-left: 8px;

      @include desktop {
        margin-left: 0;
        order: 1;
      }

      svg {
        width: 16px;
        height: 16px;
        transition: 0.3s;

        rect {
          transition: 0.3s;
        }
      }

      &_burgerMenu {


        svg {
          transform: rotate(-90deg);

          rect {
            &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8) {
              opacity: 0;
            }
          }
        }
      }


    }
  }
}