@import "../../assets/styles/app";

.joinTheFix3 {
  @include container;
  position: relative;
  //overflow: hidden;
  padding-bottom: 32px;

  .linesWrapper {
    position: absolute;
    z-index: 1;
    width: 320px;
    height: 320px;

    &:nth-child(1) {
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      @include desktop {
        left: calc(50% - 440px);
        transform: translateX(-50%);
      }
    }
  }

  .back {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .inner {
    @include inner;
    position: relative;
    z-index: 2;
    padding-top: 128px;

    @include desktop {
      padding-top: 136px;
    }

    .title {

      p {
        @include Hind;
        font-size: 52px;
        line-height: 85%;
        letter-spacing: -0.02em;
        text-align: center;
        color: #fff;

        @include desktop {
          font-size: 120px;
        }

        &:nth-child(2) {
          margin-top: 8px;

          @include desktop {
            margin-top: 0;
          }

        }
      }
    }

    .lottieWrapper {
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 246px;
      transform: translateX(-50%);
      width: 100vw;
      //width: 486px;
      //height: 212px;

      @include desktop {

        //width: 1438px;
        //height: 628px;
        top: 310px;
      }
    }


    .content {
      margin-top: 144px;

      @include desktop {
        //margin-top: 200px;
        margin-top: calc((200 / 1440) * 100vw);
      }

      .card {
        position: relative;
        z-index: 3;
        width: 363px;
        background: rgba(1, 3, 2, 0.64);
        border-radius: 20px;
        padding: 24px 32px 32px;
        margin-left: auto;
        margin-right: auto;

        @include desktop {
          width: 600px;

        }

        .cardTitle {
          @include Hind;
          font-weight: 500;
          font-size: 14px;
          line-height: 125%;
          text-transform: uppercase;
          text-align: center;
          color: rgba(255, 255, 255, 0.5);

          @include desktop {
            font-size: 16px;
          }
        }

        .progress {
          position: relative;
          z-index: 1;
          margin-top: 16px;
          left: -20px;
          right: -20px;
          width: calc(100% + 40px);
          height: 7px;
          overflow: hidden;

          @include desktop {
            margin-top: 24px;
            width: 100%;
            left: 0;
            right: 0;
            border-radius: 999px;
            background: rgba(255, 255, 255, 0.1);
          }

          .progressValue {
            position: absolute;
            z-index: 2;
            left: 0;
            top: 0;
            height: 100%;
            width: 75%;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.1), #FAEC5A);
            border-radius: 999px;

            //&::after {
            //  content: "";
            //  display: block;
            //  position: absolute;
            //  z-index: 3;
            //  width: 7px;
            //  height: 7px;
            //  top: 0;
            //  right: 0;
            //  filter: blur(9.399999618530273px);
            //  background: #faec5a;
            //}
          }

        }

        .timerValues {
          display: flex;
          justify-content: center;
          margin-top: 16px;

          @include desktop {
            margin-top: 24px;
          }

          .timerItem {
            display: flex;
            flex-direction: column;
            align-items: center;

            &:not(:first-child) {
              margin-left: 36px;
              position: relative;

              &::before {
                position: absolute;
                left: -20px;
                top: 8px;
                content: ":";
                @include Hind;
                font-size: 36px;
                color: rgba(255, 255, 255, 0.2);
              }


            }

            p:nth-child(1) {
              @include Hind;
              font-size: 48px;
              line-height: 125%;
              letter-spacing: -0.04em;
              text-transform: uppercase;
              text-align: center;
              color: #fff;
            }

            p:nth-child(2) {
              font-size: 14px;
              line-height: 125%;
              text-align: center;
              color: rgba(255, 255, 255, 0.5);
            }
          }
        }

        .form {
          margin-top: 16px;

          @include desktop {
            margin-top: 24px;
          }

          .fieldWrapper {
            position: relative;

            .submitBtnMobile {
              @include button;
              @include onlyMobile(flex);
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              right: 13px;
              width: 38px;
              height: 38px;
              border-radius: 50%;
              background: rgba(255, 255, 255, 0.1);
            }
          }

          .submitBtnDesktop {
            @include onlyDesktop();
            margin-top: 24px;
            height: 60px;
          }
        }

        .info {
          margin-top: 16px;
          font-size: 16px;
          line-height: 140%;
          text-align: center;
          color: rgba(255, 255, 255, 0.5);

          span {
            font-weight: 500;
            color: #fff;
          }
        }

        .socialLinks {
          margin-top: 32px;
          display: flex;
          justify-content: center;

          a {
            display: block;
            transition: 0.3s;
            width: 24px;
            height: 24px;

            &:not(:first-child) {
              margin-left: 12px;
            }

            &:hover {
              @include desktop {
                opacity: 0.5;
              }
            }

            svg {
              width: 24px;
              height: 24px;

              path {
                fill: #FFF;
                opacity: 1;
              }
            }
          }
        }


      }

      .itemsSection {
        margin-top: 48px;

        @include desktop {
          width: 536px;
          margin-top: 64px;
          margin-left: auto;
          margin-right: auto;
        }

        .item {

          &:nth-child(1) {
            position: relative;
            padding-bottom: 32px;

            &::after {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              height: 1px;
              background: linear-gradient(90deg, #FFF, rgba(255, 255, 255, 0.5));
              opacity: 0.1;
            }
          }

          &:nth-child(2) {
            padding-top: 32px;
          }

          p:nth-child(1) {
            font-weight: 500;
            font-size: 20px;
            line-height: 125%;
            color: #fff;
          }

          p:nth-child(2) {
            margin-top: 12px;
            @include Hind;
            font-size: 18px;
            line-height: 140%;
            color: rgba(255, 255, 255, 0.4);
          }
        }
      }

    }

  }


}