@import "../../assets/styles/app";

.tabsCard {
  position: relative;
  z-index: 3;
  @include container;
  padding-top: 48px;

  @include desktop {
    padding-top: 32px;
  }

  .inner {
    @include inner;

    .tabsDesktop {
      @include onlyDesktop(grid);
      width: 345px;
      margin-left: auto;
      margin-right: auto;
    }


    .card {
      border-radius: 20px;
      background: rgba(1, 3, 2, 0.64);
      padding: 20px;

      @include desktop {
        padding: 48px;
        margin-top: 32px;
        width: 957px;
        margin-left: auto;
        margin-right: auto;
      }

      .tabsMobile {
        @include onlyMobile(grid);
        margin-bottom: 24px;
      }


    }

    .leaderboard {
      position: relative;
      z-index: 3;
      margin-top: 24px;
      @include onlyMobile(flex);
      align-items: center;
      justify-content: center;

      svg {
        width: 20px;
        height: 20px;
      }

      span {
        white-space: nowrap;
        margin-left: 6px;
        @include Hind;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: #fff;
      }
    }

    .contentBottom {
      margin-top: 48px;

      @include desktop {
        margin-top: 16px;
        width: 957px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 20px;
        background: rgba(1, 3, 2, 0.64);
        padding: 48px;
      }


      .descriptionTop {
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: rgba(255, 255, 255, 0.4);

        @include desktop {
          width: 615px;
          margin-left: auto;
          margin-right: auto;
        }

        span {
          color: #fff;
        }
      }

      .groups {
        margin-top: 24px;

        @include desktop {
          margin-top: 0;
        }

        .group {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 20px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);

          @include desktop {
            padding-bottom: 26px;
            padding-left: 190px;
            padding-right: 190px;
            border-bottom: none;
            position: relative;

            &::after {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              bottom: 0;
              height: 1px;
              width: 100%;
              opacity: 0.1;
              background: linear-gradient(90deg, #FFF, rgba(255, 255, 255, 0.5));
            }


          }

          &:not(:first-child) {
            padding-top: 16px;
          }

          &:first-child {
            @include desktop {
              padding-top: 26px;
            }
          }

          .left {
            display: flex;
            align-items: center;

            p:nth-child(1) {
              font-weight: 500;
              font-size: 20px;
              line-height: 125%;
              color: #fff;
              width: 77px + 12px;

              @include desktop {
                //width: 77px + 16px;
              }
            }

            p:nth-child(2) {
              @include centerRow;
              width: 57px;
              height: 29px;
              border-radius: 999px;
              background: #f7fa5a;
              //margin-left: 12px;

              @include desktop {
                width: 90px;
                height: 29px;
                //margin-left: 16px;
              }

              span {
                font-weight: 500;
                font-size: 16px;
                line-height: 140%;
                letter-spacing: -0.04em;
                color: #000;

                &:nth-child(1) {
                  @include onlyDesktop(inline);
                  margin-right: 4px;
                }
              }

            }
          }

          .right {

            @include desktop {
              display: flex;
              margin-left: 16px;
            }

            p {
              font-size: 18px;
              line-height: 140%;

              @include desktop {
                white-space: nowrap;
              }

              &:nth-child(1) {
                font-weight: 500;
                color: #fff;
              }

              &:nth-child(2) {
                font-weight: 400;
                color: rgba(255, 255, 255, 0.4);
                margin-left: 6px;
              }
            }
          }

        }


      }

      .descriptionBottom {
        @include Hind;
        font-size: 18px;
        line-height: 140%;
        text-align: center;
        color: rgba(255, 255, 255, 0.4);
        margin-top: 24px;

        @include desktop {
          //width: 497px;
          margin-top: 20px;
          //padding-left: 24px;
          //padding-bottom: 24px;
          text-align: left;
        }

      }


    }

  }
}