@import "../../assets/styles/app";

.burgerMenu {
  //@include onlyMobile();
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  right: 0;
  height: 100vh;
  background: #010302;
  transform: translateX(101%);
  transition: 0.3s;

  &_burgerMenu {
    transform: translateX(0%);
  }

  .links {
    padding-top: 146px;

    .link {
      text-decoration: none;
      padding-left: 24px;
      padding-right: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 24px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      p:nth-child(1) {
        font-weight: 500;
        font-size: 24px;
        line-height: 125%;
        color: #fff;
      }

      p:nth-child(2) {
        font-size: 16px;
        line-height: 125%;
        text-align: right;
        color: rgba(255, 255, 255, 0.5);
      }

      &:not(:first-child) {
        padding-top: 24px;
      }

    }
  }

}