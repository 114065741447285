@import "../../../assets/styles/app";

.selectField {
  position: relative;

  .btn {
    @include button;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 999px;
    transition: 0.3s;

    &:hover {
      @include desktop {
        background: rgba(255, 255, 255, 0.1);
      }
    }

    svg:nth-child(1) {
      width: 24px;
      height: 24px;
    }

    p {
      margin-left: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 125%;
      color: rgba(255, 255, 255, 0.4);

      span {
        font-weight: 500;
        color: #fff;
      }
    }

    svg:nth-child(3) {
      width: 16px;
      height: 16px;
      margin-left: 2px;
      transition: 0.3s;
    }

    &_open {
      background: rgba(255, 255, 255, 0.1);

      svg:nth-child(3) {
        transform: rotate(-180deg);
      }
    }
  }

  .list {
    position: absolute;
    z-index: 10;
    left: 0;
    width: 100%;
    top: 42px;
    backdrop-filter: blur(32px);
    background: #1B1D1C;
    border-radius: 16px;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .item {
      @include button;
      display: flex;
      align-items: center;
      padding: 4px;
      border-radius: 999px;
      transition: 0.3s;

      &:not(:first-child) {
        margin-top: 4px;
      }

      &:hover {
        @include desktop {
          background: rgba(255, 255, 255, 0.05);
        }
      }

      p {
        margin-left: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 125%;
        color: rgba(255, 255, 255, 0.4);

        span {
          font-weight: 500;
          color: #fff;
        }
      }
    }
  }
}