.app {
  position: relative;
  background-color: #010302;
  background-image: url(../../assets/png/background.png);
  background-repeat: repeat;
  min-height: 100vh;
  overflow: hidden;

  &_burgerMenu {
    overflow: hidden;
    height: 100vh;
  }

  main {
    position: relative;
    z-index: 10;
  }
}