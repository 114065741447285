.runningStringItem {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .part {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;

    &_vertical {
      height: auto;
      width: 100%;
      flex-direction: column;
    }
  }
}