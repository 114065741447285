@import "../../../assets/styles/app";

.stake {

  .cardTitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 125%;
    text-align: center;
    color: #fff;
  }

  .subTitle {
    margin-top: 8px;
    @include Hind;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #36a534;
  }

  .section {
    margin-top: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "first second"
    "third third";

    & > div:nth-child(1) {
      grid-area: first;
    }

    & > div:nth-child(2) {
      grid-area: second;
      border-left: 1px solid rgba(255, 255, 255, 0.1);
    }

    & > div:nth-child(3) {
      grid-area: third;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    & > div {
      @include centerColumn;
      height: 82px;

      p:nth-child(1) {
        font-weight: 600;
        font-size: 16px;
        line-height: 125%;
        color: #fff;
      }

      p:nth-child(2) {
        margin-top: 4px;
        font-size: 14px;
        line-height: 125%;
        text-align: center;
        color: rgba(255, 255, 255, 0.5);
      }

    }

  }

  .available {
    margin-top: 20px;
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }

    p {
      margin-left: 4px;
      font-size: 14px;
      line-height: 125%;
      color: rgba(255, 255, 255, 0.4);

      span {
        color: #fff;
      }
    }


  }

  .form {
    margin-top: 12px;

    @include desktop {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .field {
      @include desktop {
        width: 443px;
      }
    }

    .btns {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 8px;
      margin-top: 24px;

      @include desktop {
        margin-top: 0;
        display: flex;
      }

      .btn {
        height: 40px;

        &:not(:first-child) {
          margin-left: 8px;
        }

        @include desktop {
          width: 170px;
        }

      }
    }


  }



}