@import "../../../assets/styles/app";

.tabs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .tab {
    @include button;
    @include centerRow;
    transition: 0.3s;
    height: 38px;
    border-radius: 999px;

    svg {
      width: 20px;
      height: 20px;

      path {
        stroke: rgba(255, 255, 255, 0.4);
        stroke-opacity: 1;
        transition: 0.3s;
      }
    }

    p {
      @include Hind;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      color: rgba(255, 255, 255, 0.4);
      margin-left: 6px;
      transition: 0.3s;
      position: relative;
      top: 1px;
    }

    &_active {
      background: #FFF;

      svg {
        path {
          stroke: #010302;
        }
      }

      p {
        color: #010302;
      }
    }
  }

  & > p {
    display: flex;
    align-items: center;
    padding: 0px 20px;

    svg {
      width: 20px;
      height: 20px;
    }

    span {
      @include Hind;
      font-size: 16px;
      line-height: 140%;
      color: rgba(255, 255, 255, 0.4);
      margin-left: 6px;
    }
  }
}