@import "../../../assets/styles/app";

.thumbComponent {
  @include centerRow;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #f7fa5a;

  .inner {
    @include centerRow;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #010302;
  }
}