@import "../../../assets/styles/app";

.buy {

  .title {
    display: flex;
    justify-content: space-between;

    .titleItem {
      &:nth-child(1) {
        p {
          text-align: left;
        }
      }

      &:nth-child(2) {
        p {
          text-align: right;
        }
      }

      p:nth-child(1) {
        font-weight: 500;
        font-size: 24px;
        line-height: 125%;
        color: #fff;

        @include desktop {
          margin-top: 32px;
        }
      }

      p:nth-child(2) {
        margin-top: 8px;
        @include Hind;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: rgba(255, 255, 255, 0.4);

        @include desktop {
          margin-top: 6px;
        }
      }
    }
  }

  .currencyTopMobile {
    margin-top: 24px;
    @include onlyMobile();
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #fff;

    span {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .currencyDesktop {
    @include onlyDesktop(flex);
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;

    p:nth-child(1) {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #fff;

      svg {
        position: relative;
        top: 6px;
      }

      span {
        color: rgba(255, 255, 255, 0.5);
      }
    }

    p:nth-child(2) {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      text-align: center;
      color: #fff;

      span {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .sliderWrapper {
    position: relative;
    margin-top: 12px;
    background: #313332;
    padding: 0px 14px;
    height: 28px;
    border-radius: 999px;

    @include desktop {
      margin-top: 16px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 28px;
      width: 14px;
      border-radius: 14px 0 0 14px;
      background: #f7fa5a;

    }

  }

  .currencyBottomMobile {
    @include onlyMobile(flex);
    justify-content: space-between;
    margin-top: 12px;

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      text-align: right;
      color: #fff;

      &:nth-child(1) {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .info {
    margin-top: 24px;
    display: flex;
    align-items: center;

    svg {
      width: 16px;
      height: 16px;
    }

    span {
      @include Hind;
      font-size: 14px;
      line-height: 125%;
      color: rgba(255, 255, 255, 0.45);
      margin-left: 4px;
    }
  }

  .form {
    margin-top: 8px;

    .fields {
      @include desktop {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 16px;
      }

      .receiveField {
        position: relative;

        &:not(:first-child) {
          margin-top: 8px;

          @include desktop {
            margin-top: 0;
          }
        }

        .select {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 15px;
        }
      }

    }

    .btns {
      margin-top: 24px;
      display: flex;
      flex-direction: column;

      @include desktop {
        margin-top: 32px;
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      .btnsRightPart {

        @include desktop {
          display: flex;
          align-items: center;
        }

        .leaderboard {
          @include onlyDesktop(flex);
          align-items: center;

          svg {
            width: 20px;
            height: 20px;
          }

          span {
            white-space: nowrap;
            margin-left: 6px;
            @include Hind;
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            letter-spacing: -0.02em;
            color: #fff;
          }
        }

        .connectWallet {
          height: 60px;

          @include desktop {
            height: 40px;
            margin-left: 16px;
            width: 260px;
          }
        }
      }


      .howToBuyLink {
        margin-top: 12px;
        text-decoration: none;
        @include Hind;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.02em;
        text-align: center;
        color: rgba(255, 255, 255, 0.5);

        @include desktop {
          margin-top: 0;
        }
      }

    }


  }


}