@import "../../../assets/styles/app";

.lines {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .vertical {
      position: absolute;
      top: 0;
      height: 100%;
      width: 1px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.005) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.005) 100%);
    }

    .horizontal {
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.005) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0.005) 100%);
    }

    .animatedLinesWrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .horizontalLineWrapper {
        position: absolute;
        left: 0;
        height: 1px;
        width: 100%;

        .itemHorizontal {
          height: 100%;

          .gradient {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 80px;
            background: linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%);
          }
        }
      }
    }

  }
}