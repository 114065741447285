@import "../../assets/styles/app";

.phase11 {
  @include container;
  position: relative;
  padding-top: 96px;

  @include desktop {
    padding-top: 128px;
  }

  .linesWrapper {
    position: absolute;
    z-index: 1;
    width: 320px;
    height: 320px;

    &:nth-child(1) {
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      @include desktop {
        left: calc(50% - 440px);
        transform: translateX(-50%);
      }
    }
  }

  .back {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  .inner {
    @include inner;
    position: relative;
    z-index: 2;

    h1.title {
      @include Hind;
      font-size: 64px;
      line-height: 85%;
      letter-spacing: -0.02em;
      text-align: center;
      background: linear-gradient(218deg, #f7fa5a 0%, #f7fa5a 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @include desktop {
        font-size: 120px;
      }
    }

    .label {
      margin-top: 24px;
      @include Hind;
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      text-transform: uppercase;
      text-align: center;
      color: rgba(255, 255, 255, 0.4);

      @include desktop {
        margin-top: 32px;
      }
    }

    .timer {
      display: flex;
      justify-content: center;
      margin-top: 12px;

      .timerItem {
        @include centerColumn;
        position: relative;
        border-radius: 12px;
        width: 74px;
        height: 74px;
        background: rgba(1, 3, 2, 0.48);

        @include desktop {
          border-radius: 20px;
          width: 112px;
          height: 112px;
        }


        &:not(:first-child) {
          margin-left: 21px;

          &::before {
            content: "";
            display: block;
            position: absolute;
            border-radius: 50%;
            backdrop-filter: blur(21.899999618530273px);
            background: rgba(1, 3, 2, 0.64);
            width: 5px;
            height: 5px;
            top: 28px;
            left: -13px;

            @include desktop {
              top: 47px;
              left: -13px;
            }
          }

          &::after {
            content: "";
            display: block;
            position: absolute;
            border-radius: 50%;
            backdrop-filter: blur(21.899999618530273px);
            background: rgba(1, 3, 2, 0.64);
            width: 5px;
            height: 5px;
            bottom: 28px;
            left: -13px;

            @include desktop {
              bottom: 47px;
              left: -13px;
            }
          }
        }

        p:nth-child(1) {
          font-family: var(--second-family);
          font-weight: 400;
          font-size: 32px;
          line-height: 125%;
          letter-spacing: -0.04em;
          text-transform: uppercase;
          text-align: center;
          color: #fff;

          @include desktop {
            font-size: 64px;
          }
        }

        p:nth-child(2) {
          @include Hind;
          font-weight: 400;
          font-size: 14px;
          line-height: 125%;
          text-align: center;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }

  }
}