@import "../../../assets/styles/app";

.buttonCustom {
  @include button;
  @include centerRow;
  height: 40px;
  width: 100%;
  border-radius: 999px;
  transition: 0.3s;

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: -0.02em;
  }

  &_contained {
    background: #f7fa5a;

    &:hover {
      @include desktop {
        background: #f8ea5a;
      }
    }

    span {
      color: #000;
    }
  }

  &_contained_mouseDown {
    background: #ffea0e;

    &:hover {
      @include desktop {
        background: #ffea0e;
      }
    }
  }

  &_outlined {
    border: 1px solid rgba(255, 255, 255, 1.0);

    span {
      color: #fff;
    }

    &:hover {
      @include desktop {
        background: rgba(255, 255, 255, 0.1);
        border-color: transparent;
      }
    }
  }

  &_outlined_mouseDown {
    background: rgba(255, 255, 255, 0.2);

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
}